@font-face {
  font-family: "Magnifika";
  src: url("/public/fonts/Magnifika.otf") format("truetype");
  font-display: swap;
}

@font-face {
  font-family: "MBF";
  src: url("/public/fonts/MBF-m.ttf") format("truetype");
  font-display: swap;
}

@import url("https://fonts.googleapis.com/css2?family=Outfit:wght@100..900&display=swap");

html,
body {
  font-family: "Outfit", sans-serif;
  margin: 0;
  padding: 0;
  
  overflow: hidden;
}

main {
  flex: 1; /* Ocupa el espacio restante entre header y footer */
  display: flex;
  flex-direction: column;
} 

.app {
  font-family: "Outfit", sans-serif;
  margin: 0;
  overflow: hidden;
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #191919;
  color: #ecdbba;
}

.header {
  position: relative; /* Permite posicionar elementos hijos absolutamente */
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  background-color: #191919;
  padding: 0.3em 0.5em 0.2em;
  box-sizing: border-box;
  font-family: "Magnifika";
  font-size: 1em;
  color: #ecdbba;
  border-bottom: 3px solid #ecdbba;
}

.logo-container {
  text-align: center;
  width: 100%; /* Asegura que ocupe todo el ancho */
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.2em 0; /* Espaciado interno */
}

.logo {
  display: block;
  margin: 0 auto;
}

.menu-container {
  position: absolute;
  top: 1.2rem;
  right: 1rem;
  z-index: 100; /* Asegúrate de que esté por encima de otros elementos */
}

.btn {
  position: relative;
  width: 30px;
  height: 5px;
  cursor: pointer;
}

.btn-line {
  display: block;
  width: 85%;
  height: 3px;
  background: #ecdbba;
  border-radius: 3px;
  position: absolute;
  left: 0;
  transition: all 0.3s ease;
}

.btn-line:nth-child(1) {
  top: 0;
}

.btn-line:nth-child(2) {
  top: 7px;
}

.btn-line:nth-child(3) {
  top: 14px;
}

.btn.active .btn-line:nth-child(1) {
  transform: rotate(45deg);
  top: 10px;
}

.btn.active .btn-line:nth-child(2) {
  opacity: 0;
}

.btn.active .btn-line:nth-child(3) {
  transform: rotate(-45deg);
  top: 10px;
}

/* Overlay para oscurecer el fondo */
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7); /* Oscurecimiento semitransparente */
  z-index: 98; /* Debajo del sidebar */
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.3s ease;
  backdrop-filter: blur(3px); /* Aplica un desenfoque al fondo */
}

.overlay.visible {
  opacity: 1;
  pointer-events: all;
}

/* Sidebar */
.sidebar {
  position: fixed;
  top: 0;
  right: -300px;
  width: 270px;
  height: 100%;
  background: #191919; /* Fondo azul oscuro */
  box-shadow: -4px 0 10px rgba(0, 0, 0, 0.2); /* Sombra para separación */
  z-index: 99;
  transition: all 0.3s ease;
  overflow-y: auto;
  font-family: "Outfit", sans-serif;
  font-weight: 600;

}


/* Overlay para perfil */
.profile-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
  backdrop-filter: blur(5px);
  
  
}

.profile {
  background-color: #191919;
  color: #ecdbba;
  padding: 2rem;
  border-radius: 10px;
  width: 275px;
  height: 500px;
  text-align: center;
  position: relative;
  font-family: "Outfit", sans-serif;
  font-weight: 600;
  transition: all 0.3s ease;
}

.profile-header{
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 1rem;
  flex-direction: column;
  gap: .5rem;
}

.close-profile {
  position: absolute;
  top: 10px;
  left: 10px;
  cursor: pointer;
}

.user-avatar-large {
  width: 75px;
  height: 75px;
  border-radius: 50%;
  margin: 0 10px;
  display: block;
}

.profile-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  
}

.logout-button {
  background-color: #c84b31;
  color: white;
  border: none;
  padding: 10px 15px;
  border-radius: 5px;
  cursor: pointer;
  width: 100%;
  position: relative;
  font-size: 1rem;
  font-weight: 600;
  transition: background-color 0.3s ease;
  top: 300px;
}

.logout-button:hover {
  background-color: #a33e28;
}

.sidebar.open {
  right: 0;
}

/* Lista del Sidebar */
.sidebar-list {
  list-style: none;
  padding: 4.5rem 0rem 4.5rem 1rem;
  margin: 0;
}

.sidebar-item {
  margin-bottom: .8rem;
}



.sidebar-content:hover {
  background-color: #ecdbba; /* Color de hover */
  transform: scale(1.02); /* Ligera ampliación */
}

.sidebar-content {
  display: flex;
  align-items: center;
  padding: 0.8rem;
  background-color: #2d4263; /* Color de fondo del item */
  border-radius: 25px 0px 0px 25px; /* Bordes redondeados */
  transition: background-color 0.3s ease, transform 0.2s ease;
  cursor: pointer;
  color: #ecdbba; /* Color de texto */
}

.bx {
  line-height: 0;

  font-weight: 500;
}

.sidebar-content .sidebar-icon {
  width: 32px;
  height: 32px;
  margin-right: 1rem;
  background-color: #ecdbba;
  padding: .2em;
  border-radius: 25%;
  color: #2d4263;
  align-items: center;
  display: flex;
  justify-content: center;
  line-height: 0;
  
}

.user-avatar {
  padding: 0;
  width: 32px !important;
  height: 32px !important;
  border-radius: 25%;
  object-fit: cover;
}

.sidebar-icon img {
  width: 25px;
  height: 25px;
}




.sidebar-content span {
  font-size: 1rem;
  font-weight: 600;
  color: inherit;
}

.sidebar-footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  padding: 1rem;
  background-color: #2d4263;
  border-top: 3px solid #ecdbba; /* Borde superior */
  box-sizing: border-box; /* Asegura que el padding no afecte al ancho */
  align-items: center;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  gap: .4rem;
  font-size: .8rem;
  height: 200px;
}


.history-button {
  display: flex;
  align-items: center;
  background-color: transparent;
  border: none;
  color: #ecdbba;
  cursor: pointer;
  font-size: 1rem;
}

.history-button img {
  width: 20px;
  height: 20px;
  margin-right: 0.5rem; /* Espacio entre el ícono y el texto */
}

.points-button {
  background-color: #ecdbba;
  border: none;
  border-radius: 5px;
  color: #191919;
  cursor: pointer;
  padding: 0.5rem 1rem;
  font-size: 1rem;
}

.points-button:hover {
  background-color: #c84b31;
  color: #ecdbba;
  transition: all 0.3s ease;
}




.header-right {
  
  display: flex;
  justify-content: right;
  align-items: center;
  padding: 0; /* Elimina el padding de los laterales */
  width: 33.3%;
}

.header-center {
  flex: 1 1 100%;
  text-align: center;
  
  align-items: center;
  justify-content: center;
  padding: 0; /* Elimina el padding de los laterales */
  font-family: "MBF";
  width: 25%;
}

.header-left {
  
  display: flex;
  justify-content: right;
  align-items: center;
  padding: 0; /* Elimina el padding de los laterales */
  width: 25%;
}



.history-var-img {
  width: 10px;
  height: 10px;
  color: black;
  display: flex;
}



.header-center h1 {
  margin: 0; /* Elimina márgenes adicionales */
  padding: 0; /* Asegura que no haya padding extra */
  font-size: 2.3em; /* Ajusta el tamaño del texto en el h1 */
  line-height: 1; /* Reduce la altura de línea */
  display: flex;
  flex-direction: row;
}


.svg-icon {
  width: 22px;
  height: 24px;
  fill: currentColor; /* Hereda el color del texto */
  vertical-align: middle; /* Alineación vertical */
}





.finished-message {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.revancha-buttons {
  display: flex;
  flex-direction: column;
  gap: 10px; /* Espacio entre los botones */
  justify-content: center; /* Centra los botones */
}

.restart-button {
  background-color: #c84b31;
  color: #ecdbba;
  height: 35px;
  border: none;
  font-weight: bold;
  font-size: 1em;
  border-radius: 50px;
  cursor: pointer;
  width: 70%;
  margin: 0 auto;
}

a {
  color: darkslateblue;
}

.finished-message > h3 {
  font-size: 2em;
  text-align: center;
  justify-content: center;
}

.winner {
  margin-top: 1.2em;
  display: flex;
  flex-direction: column-reverse;
}

.winner p {
  margin-top: 0;
  line-height: 1;
}

.winner img {
  height: auto;
  width: 120px;
  margin: 0 auto;
}

.feedback-section {
  margin-top: 0.1em;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  padding: 0.5em;
  width: 80%;
  max-width: 600px; /* Limita el ancho máximo */
  margin: 0 auto; /* Centra el contenedor */
}

.feedback-section h4 {
  font-size: 1.2em;
  margin-bottom: 0.5em;
  color: #ecdbba;
}

.feedback-section textarea {
  width: 100%;
  border-radius: 10px;
  border: 2px solid #ecdbba;
  background-color: #191919;
  color: #ecdbba;
  padding: 0.5em;
  font-size: 1em;
}

.submit-feedback-button {
  background-color: transparent;
  color: #ecdbba;
  border: 2px solid #ecdbba;
  border-radius: 20px;
  padding: 0.5em 1em;
  margin-top: 0.5em;
  cursor: pointer;
  font-size: 1em;
  font-weight: bold;
}

.submit-feedback-button:hover {
  background-color: #ecdbba;
  color: #191919;
  transform: scale(1.1);
}

.footer {
  display: flex;
  justify-content: center; /* Espacio entre los elementos */
  align-items: center; /* Centra verticalmente */
  padding: 0em 20px; /* Espacio interno */
  width: 100%; /* Ocupa todo el ancho del contenedor */
  background-color: #2d4263; /* Color de fondo */
  border-top: 3px solid #ecdbba; /* Borde superior */
  box-sizing: border-box; /* Asegura que el padding no afecte al ancho */
  font-size: 0.9rem;
  gap: 1rem; /* Espacio entre los elementos */
  height: 35px;
  flex-direction: row;
  line-height: 1;
}

.footer__copy,
.footer__version {
  display: flex; /* Permite que el contenido interno se alinee correctamente */
  align-items: center; /* Centra verticalmente */
  color: #ecdbba; /* Color de texto */
  margin: 0; /* Elimina márgenes innecesarios */

}

h1,
h3 {
  margin-block-start: 0;
  margin-block-end: 0;
}

.footer__title {
  /* font-family: MBF; */
  font-family: "MBF";
  text-align: center;
  font-weight: bold;
  font-size: 1.1rem;
  margin: 0 .4rem 0 .2rem;
  /* margin-bottom: 2rem; */
}

/* .footer__copy {
  display: block;
  color: #ecdbba;
  text-align: center;
  font-size: 1rem;
  font-weight: normal;
} */

a:link,
:visited,
:hover,
:active {
  text-decoration: none;
  color: #ecdbba;
}




/* media screen */
@media screen and (max-height: 700px) {
  .app {
    overflow-x: hidden;
    overflow-y: scroll;

  }
}



